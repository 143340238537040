import type { LoadScriptProps } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';

import { SanityDoctorOffice } from '~types/sanity';

export const additionalLibraries = [
  'places',
  'geometry',
] as LoadScriptProps['libraries'];

export const mapOptions = {
  controlSize: 25,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export const getMapBounds = (
  map: google.maps.Map,
  geoLocations: SanityDoctorOffice['geolocation'][],
) => {
  if (!map) return;
  if (geoLocations.length <= 1) {
    // dont change zoom if only a single geolocation
    map.setOptions({ maxZoom: map.getZoom() });
  }
  const bounds = new window.google.maps.LatLngBounds();
  geoLocations.map(({ lat, lng }) => {
    bounds.extend(new window.google.maps.LatLng(lat, lng));
  });

  map.fitBounds(bounds);
  // unset
  map.setOptions({ maxZoom: undefined });
};

export const getDistance = (
  locationA: SanityDoctorOffice['geolocation'],
  locationB: SanityDoctorOffice['geolocation'],
) => {
  return (
    google.maps.geometry.spherical.computeDistanceBetween(
      locationA,
      locationB,
    ) * 0.000621371192
  );
};

export const googleMapsLoaderProps = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY ?? '',
  libraries: additionalLibraries,
};

export const useGoogleMapInstance = () => {
  const { isLoaded } = useJsApiLoader(googleMapsLoaderProps);

  const [googleMapInstance, setGoogleMapInstance] =
    useState<google.maps.Map | null>(null);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setGoogleMapInstance(map);
  }, []);
  const onUnmount = useCallback(function callback() {
    setGoogleMapInstance(null);
  }, []);

  return { isLoaded, googleMapInstance, onLoad, onUnmount };
};
