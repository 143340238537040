import Sticky from 'react-stickynode';

import { Box, Container, Flex, Section, Spacer } from 'voom-gatsby';

import {
  BunionplastyBackground,
  DistanceFilter,
  GeolocationForm,
  IconToggle,
} from '~components';

export const DoctorResultsForm = ({
  showDistanceFilter,
}: {
  // only show distance filter on doctor results page, not on state or city pages
  showDistanceFilter: boolean;
}) => {
  return (
    <Section
      sx={{
        height: [84, null, 103],
        py: [0, 0, 0],
        overflow: 'visible',
        zIndex: 2,
      }}
    >
      <Sticky top={0} innerZ={1}>
        <Box
          sx={{
            height: [84, null, 103],
            py: [0, 0, 0],
            display: 'flex',
            alignItems: 'center',
            bg: 'beige',
            position: 'relative',

            transition: 'height 0.2s ease-in-out',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              overflow: 'hidden',
              height: 'full',
              width: 'full',
            }}
          >
            <BunionplastyBackground
              sx={{
                width: [505, null, 685],
                height: [475, null, 646],
                left: [-209, null, -201],
                top: [-189, null, -240],
                svg: {
                  color: 'pink',
                  opacity: 0.07,
                },
              }}
            />
          </Box>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: [3, null, 5, 6],
            }}
          >
            <GeolocationForm
              sx={{
                width: ['full', 'full', 650],
                flex: ['0 1 100%', null, '0 1 650px'],
                height: [55, null, 65],
              }}
            />
            <Flex sx={{ flex: '0 0 auto', alignItems: 'center', zIndex: 1 }}>
              <Spacer direction="horizontal" space={1} />
              <IconToggle sx={{ display: [null, null, null, 'none'] }} />
              <Spacer direction="horizontal" space={1} />
              {showDistanceFilter && <DistanceFilter />}
            </Flex>
          </Container>
        </Box>
      </Sticky>
    </Section>
  );
};
